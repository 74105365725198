import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import $auth from '@/common/auth'
import $store from '@/store/store'
//import MessageService from '../../services/socialnetwork/MessageService'

var stompClient = null;
var name = null;
var token = null;
//var _messageService = new MessageService();
var socket = null;

export default class WebSocketService {
  constructor() {
    socket = new SockJS(process.env.VUE_APP_API_URL +'/euprotegido/ws');
  }

  Connect() {
    var thizz = this;
    name = $auth.user().name;
    token = $auth.getAccessToken();

    console.log('StompClient Init: ', stompClient)
    if (name && (!stompClient || !stompClient.connected)) {
      stompClient = Stomp.over(socket);
      stompClient.debug = null;

      var header = {
        authorization: `${token}`
      }

      console.log('StompClient Before Connect: ', stompClient)
      stompClient.connect(header, this.ConnectionSuccess, (e) => this.ConnectionFail(thizz, e));

      let user = $auth.user();
      user.websocket = true;
      $auth.setUser(user);
      console.log('WebSocket: Connecting End')
    }
  }

  ConnectionFail(thisService, e) {
    console.log('retry connection to websocket', e)
    thisService.Disconnect();
    setTimeout(() => {
      thisService.Connect();
    }, 2000)

  }

  ConnectionSuccess() {
    const channelAccessTime =  $auth.getSession() ? $auth.getSession().createDate : null;
    console.log('WebSocket Connected ChatBot: ' + $auth.user().id + '-' + $auth.getAccessToken() + '-' + channelAccessTime)
    console.log('WebSocket Connected Notification: ' + $auth.user().id)
    console.log('StompClient After Connect: ', stompClient)
    stompClient.subscribe(`/topic/user-${$auth.user().id}-${$auth.getAccessToken()}-${channelAccessTime}`, (data) => WebSocketService.prototype.OnMessageReceived(data));
    stompClient.subscribe(`/topic/notifyUser-${$auth.user().id}`, (data) => WebSocketService.prototype.OnMessageReceived(data));
    stompClient.subscribe(`/topic/allUsers`, (data) => WebSocketService.prototype.OnMessageReceived(data));
  }

  SendMessage(messageContent) {
    if (messageContent && stompClient) {
      var chatMessage = {
        sender : name,
        content : messageContent,
        type : 'CHAT'
      };

      stompClient.send("/websocket/chat.sendMessage", {}, JSON.stringify(chatMessage));
    }
  }

  OnMessageReceived(payload){
    try {
      const message = JSON.parse(payload.body);

      switch(message.type) {
        case "CHAT":
          WebSocketService.prototype.ProcessChatMessage(message)
          break;
        case "CHAT_BOT":
          WebSocketService.prototype.ProcessChatBotMessage(message);
          break;
        case "ALERT":
          WebSocketService.prototype.ProcessAlertMessage(message);
          break;
        default:
          WebSocketService.prototype.ProcessDefaultMessage(message);
      }

    }
    catch(err) {
      //vazio
    }

  }

  ProcessChatMessage(message){
    try {
      let unreadMessages = $store.getters.getCountUnreadMessages;
      $store.commit('setCountUnreadMessages', unreadMessages + 1);

      const content = JSON.parse(message.content);
      const senderId = content.senderEntity.id;
      let hasEntityMessage = false;
      let messagesGroupingBySender = $store.getters.getMessagesGroupingBySender;

      messagesGroupingBySender.forEach(function(m) {
        if (m.senderEntity.id == senderId) {
          m.lastMessage = content.lastMessage;
          m.totalMessages = content.totalMessages;
          m.unreadMessages = content.unreadMessages;
          hasEntityMessage = true;
        }
      });

      if(hasEntityMessage == false)
        messagesGroupingBySender.push(content);

      $store.commit('setMessagesGroupingBySender', messagesGroupingBySender);

      let allMessages = $store.getters.getAllMessages.filter(function( m ) {
        return m.senderId == senderId;
      });

      if(allMessages.length > 0) {
        let allSenderMessages = allMessages[0];
        allSenderMessages.messages.unshift(content.lastMessage);
        $store.commit('setAllMessagesBySender', allSenderMessages);
        //_messageService.MarkMessageAsRead(content.lastMessage.id);
        //let container = document.getElementById ( "notification-body" );
        //container.scrollTop = container.scrollHeight;
        setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
      }


    }
    catch(err) {
      setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
    }

  }

  ProcessChatBotMessage(message){
    try{
      let content = message.content;
      let buttons = [];

      content = content.replace('\\n', '<br>');

      if(content.includes('###')){
        const msg = content.split('###');
        content = msg[0];
        let msgButtons = msg[1];
        if(msgButtons.includes('abrirAtendimento')){
          buttons.push({ label: "Abrir Atendimento", action : 'abrirAtendimento'})
        }
        if(msgButtons.includes('reiniciarChat')){
          buttons.push({ label: "Nova Conversa", action : 'novaConversa'})
        }
        if(msgButtons.includes('socilitarReembolso')){
          buttons.push({ label: "Dúvida Sobre Reembolso", action : 'socilitarReembolso'})
        }
        if(msgButtons.includes('consultarRede')){
          buttons.push({ label: "Consultar Rede", action : 'consultarRede'})
        }
        if(msgButtons.includes('duvidaCoparticipacao')){
          buttons.push({ label: "Dúvida Sobre Coparticipação", action : 'duvidaCoparticipacao'})
        }
        if(msgButtons.includes('consultarCobertura')){
          buttons.push({ label: "Consultar Coberturas", action : 'consultarCobertura'})
        }
        if(msgButtons.includes('segundaViaCartao')){
          buttons.push({ label: "Segunda Via Carteirinha", action : 'segundaViaCartao'})
        }
        if(msgButtons.includes('abrirProtocoloCovid')){
          buttons.push({ label: "Protocolo Covid", action : 'abrirProtocoloCovid'})
        }
        if(msgButtons.includes('chatBotNao')){
          buttons.push({ label: "Não", action : 'chatBotNao'})
        }
        if(msgButtons.includes('chatBotSim')){
          buttons.push({ label: "Sim", action : 'chatBotSim'})
        }

        if(msg.length > 2) {
          msgButtons = msg[2];
          if(msgButtons.includes('falarComEspecialista')){
            buttons.push({ label: "Falar com Especialista", action : 'chatBotTeams'})
          }
        }

      }

      if(content === 'Connecting to agent...'){
        content = 'Conectando ao Especialista...'
      }

      //$store.commit('addChatBotMessage', content);
      const chatbotId = $store.getters.getChatBotMessages[0].chatbot;
      let chatBotMessages = $store.getters.getChatBotMessages;

      let allMessages = chatBotMessages.filter(function( m ) {
        return m.chatbot == chatbotId;
      });

      if(allMessages.length > 0) {
        let allSenderMessages = allMessages[0];
        allSenderMessages.messages.unshift({body: content, type:'bot', messageClass: 'bot-message', buttons});
        let chatbotMessages = [{chatbot: chatbotId, messages:  [...allSenderMessages] }];
        chatbotMessages = WebSocketService.prototype.RemoveRepeatMessages(chatbotMessages);
        $store.commit('setChatBotMessages', chatbotMessages);
        setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
      }
    }
    catch(err) {
      setTimeout(function(){ $store.commit('setConversationScroll');}, 500);
    }
  }

  RemoveRepeatMessages(chatBotMessages){
    let messagesToRemove = []
    chatBotMessages[0].messages.forEach((message, index) => {
      if(index > 0){
        if(JSON.stringify(chatBotMessages[0].messages[index]) == JSON.stringify(chatBotMessages[0].messages[index - 1])){
          messagesToRemove.push(index - 1);
        }
      }
    })

    messagesToRemove.forEach(index => {
      chatBotMessages[0].messages.splice(index, 1);
    })
    return chatBotMessages;
  }

  ProcessAlertMessage(message){
    try{
      const content = JSON.parse(message.content);
      $store.commit('showmsg', {
        text: content.body,
        type: content.type
      });
    }
    catch(err) {
      // vazio
    }
  }

  Disconnect(){
    if(stompClient) {
      /*$store.commit('showmsg', {
        text: "Desconectando",
        type: "info"
      });*/
      stompClient.unsubscribe();
      stompClient.disconnect({}, {});
      stompClient = null;
      /*$store.commit('showmsg', {
        text: "Chat Desconectado",
        type: "info"
      });*/
    }
  }
}

